export default {
  computed: {
    list() {
      return this.$store.state.moduleDomains.moduleDomainsContact.list;
    },
    isLoading() {
      return this.$store.state.moduleDomains.moduleDomainsContact.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleDomains/moduleDomainsContact/fetchList');
    },
  },
};
