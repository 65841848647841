export default {
  computed: {
    contextMenu() {
      if (!this.current) return [];
      if (!this.tools.edit && !this.tools.delete) return [];
      const arr = [];
      if (this.tools.edit) {
        arr.push({
          key: 'edit',
          id: this.item.id,
          tool: this.tools.edit,
          disabled: !this.tools.edit.isEnable(this.current),
        });
      }
      if (this.tools.delete) {
        arr.push({
          key: 'delete',
          color: 'del',
          id: this.item.id,
          askSure: true,
          tool: this.tools.delete,
          disabled: !this.tools.delete.isEnable(this.current),
        });
      }
      return [arr];
    },
  },
};
